import Index from "./index/index"
import Paste from "./paste/index"
export const router= ()=>{
    var href = location.href
    var route = href.split("#")[1]
    switch (route) {
        case "/index":
            return Index
        case "/paste":
            return Paste
        default:
            return Index
    }
}
