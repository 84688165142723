<template>
    <div class="box">
        <button class="btn" id="btn" data-clipboard-target="#content">点我复制</button>
        <div class="content" id="content" :value="value">{{value}}</div>
        <div style="display: flex;justify-content: center;align-items: center;">
            <iframe :src="'https://hyz.cool/qrcode/?q='+value" style="width: 518px;height: 518px;border: none"></iframe>
        </div>
    </div>
</template>

<script>
    import {getClipboardCurrent} from "@/api/api";

    import Clipboard from 'clipboard';

    export default {
        name: "index",
        data() {
            return {
                value: "",
            }
        },
        mounted() {
            this.getClipboard()

        },
        methods: {
            getClipboard() {
                getClipboardCurrent().then(res => {
                    this.value = res.data.value
                    new Clipboard('.btn')
                    document.getElementById("btn").click()
                }).catch(err => {
                        console.log(err)
                    }
                )
            }
        }
    }
</script>

<style scoped>
    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .content {
        font-size: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px;
    }

    .btn {

        border: none;
        padding: 10px;
        color: darkblue;
        font-size: larger;
        margin-top: 50px;
        box-shadow: 0 0 10px 4px #b8c2cb87;

    }

</style>
