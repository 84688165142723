<template>
    <div class="box">
        <img src="https://hyz.cool/public/R/img/link.jpg" @click="onClickToPaste">
        <p>{{result}}</p>
        <input v-model="content" placeholder="你好，续加仪！"/>
        <div v-if="content" style="display: flex;justify-content: center;align-items: center;">
            <iframe :src="'https://hyz.cool/qrcode/?q='+content" style="width: 518px;height: 518px;border: none"></iframe>
        </div>
    </div>
</template>

<script>
    import {addToClipboard} from "@/api/api";

    export default {
        name: "index",
        data() {
            return {
                result: "你好，续加仪！",
                content: ""
            }
        },
        mounted() {
            document.addEventListener("paste", this.onPaste)
        },
        methods: {
            onPaste(e) {
                var value = e.clipboardData.getData('text')
                this.dealPasteAction(value)
            },
            onClickToPaste() {
                this.dealPasteAction(this.content)
            },
            dealPasteAction(value) {
                if (value) {
                    addToClipboard({value, platform: "web"}).then(res => {
                        this.result = res.message
                        var that = this
                        setTimeout(() => {
                            that.result = "你好!续加仪"
                        }, 2000)
                    }).catch(err => {
                        console.log(err)
                    })
                }
            }
        }
    }
</script>

<style scoped>
    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .box img {
        width: 300px;
        cursor: pointer;
    }

    .box p {
        font-size: 35px;
        font-family: cursive;
    }

    .box input {
        border: none;
        box-shadow: 0 0 5px 1px #f1f1f1;
        outline: none;
        padding: 5px;
        font-size: 25px;
        font-family: cursive;
    }
</style>
